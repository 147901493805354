import {
  Injectable
} from '@angular/core';

import {
  Products
} from '../shared/class/products.class';

import * as _ from 'lodash';

enum CostType {
  PREVISIONAL = 'PREVISIONAL',
  DISTRIBUTOR = 'DISTRIBUTOR',
  FUNCTIONS = 'FUNCTIONS',
  WARRANTY = 'WARRANTY',
  OPTIONS = 'OPTIONS',
}

interface Cost {
  type: CostType;
  rate: number;
}

interface Rate {
  product: string;
  costs: Array <Cost> ;
}

interface Rates {
  rates: Array <Rate> ;
}

@Injectable({
  providedIn: 'root'
})

export class CalculatorService {
  private ratio_toner = 1.0;
  private ratio_ink = 1.0;
  private ratio_bu = 1.0;
  private ratio_wt = 1.0;
  private ratio_drum = .84;

  private products: Products;

  private needs = {
    'Printer': {
      'Printer': true,
      '3 en 1': true,
      '4 en 1': true,
    },
    '3 en 1': {
      '3 en 1': true,
      '4 en 1': true,
    },
    '4 en 1': {
      '4 en 1': true,
    },
  };

  private connectivity = {
    'USB': {
      'USB': true,
      'Wifi': true,
      'Réseau': true,
      'Wifi/Réseau': true,
    },
    'Wifi': {
      'Wifi': true,
      'Wifi/Réseau': true
    },
    'Réseau': {
      'Réseau': true,
      'Wifi/Réseau': true
    },
    'Wifi/Réseau': {
      'Wifi/Réseau': true
    }
  };

  private format = {
    'A4': {
      'A4': true,
      'A3': true
    },
    'A3': {
      'A3': true
    },
  };

  constructor(products: Products) {
    this.products = products;
  }

  // Retrieve the rates of MPS (and TRANSAC) depending on data
  public calculateTCO(simulation: any) {
    console.log('Calculate TCO', simulation);
    const mpsRates = this.calculateMPS(simulation);
    let transacRates = [];

    if (simulation.type === 'TRANSAC') {
      transacRates = this.calculateTransac(simulation);
    }

    const sortedTransacRates = this.sortRates(transacRates, 1, simulation.model_ref_sap);

    // Need to filter all the rates to return only a slice of some elements
    return _.slice(
        _.union(
        sortedTransacRates,
        this.sortRates(mpsRates, 4, simulation.model_ref_sap),
      ),
      0,
      4
    );
  }

  public getOptionsCosts(references: Array<string>) {
    const self = this;
    let costs = [];

    _.each(references, (ref) => {
      const product = self.products.getOptionByRef(ref);

      if (product !== undefined) {
        costs.push({
            type: CostType.OPTIONS,
            rate: product.distributor_price_france,
            ref_sap: product.ref_sap,
        });
      }
    });

    return costs;
  }

  public getFunctionsCosts(references: Array<string>) {
    console.log('getFunctionsCosts - START');
    console.log(references);
    const self = this;
    let costs = [];

    _.each(references, (ref) => {
      console.log(ref);
      const product = self.products.getFunctionByRef(ref);
      console.log(product);
      if (product !== undefined) {
        costs.push({
            type: CostType.FUNCTIONS,
            rate: product.distributor_price_france,
            ref_sap: product.ref_sap,
        });
      }
    });

    console.log('getFunctionsCosts - END');
    return costs;
  }

  private calculateMPS(simulation: any) {
    let rates = [],
      selectedProductRates = null;
    const self = this;

    console.log(simulation);
    // LOOP over products to find correct rates
    _.each(this.products.mps, product => {
      // console.log(
      //   'Feurst Loop',
      //   this.checkAdvancedFields(product, simulation),
      //   this.checkProductForSimulation(product, simulation)
      // );

      if (
        this.checkProductForSimulation(product, simulation)
        && (
          // Advanced fields check only for simulation wihtout targeted product
          simulation.model_ref_sap !== undefined
          || this.checkAdvancedFields(product, simulation)
        )
      ) {
        if (
          ((simulation.vol_color > 0 && product.mono_color === 'COLOR' && product.mps.color > 0) ||
          (simulation.vol_mono > 0 && simulation.vol_color === 0))
        ) {
          const tmpRate = {
              product: product.ref_sap,
              type: 'MPS',
              costs: [
                {
                  type: CostType.DISTRIBUTOR,
                  rate: product.distributor_price_france
                },
              ]
            },
            duration = simulation.duration;

          // Previsional Volume
          // Something SEEMS wrong here,
          const prev_mono = (duration * simulation.vol_mono),
            prev_color = (duration * simulation.vol_color);

          // Manage retrieval of warranty cost
          const warrantyCost = self.manageWarranty(product, duration);
          if (warrantyCost.rate > 0) {
            tmpRate.costs.push(warrantyCost);
          }

          let consumableCost = 0;

          // Manage retrieval of previsional cost
          // --- Manage retrieval of cost per page (INK)
          let prevCost = {
            type: CostType.PREVISIONAL,
            rate: 0,
          };

          let ratio = this.ratio_ink;
          if (product.techno === 'laser') {
            ratio = this.ratio_toner;
          }

          // --- Manage retrieval of cost per toner/ink consumable
          if (prev_mono > 0) {
            consumableCost += prev_mono * product.mps.mono;
          }

          if (prev_color > 0) {
            consumableCost += prev_color * product.mps.color;
            // consumableCost += prev_color * product.mps.mono;
          }

          prevCost.rate = consumableCost;
          tmpRate.costs.push(prevCost);
          rates.push(tmpRate);

          // If product == selected ref
          if (product.ref_sap === simulation.model_ref_sap) {
            selectedProductRates = tmpRate;
          }
        }
      }
    });

    // Sort RATES by total price
    // THIS will manage to exclude printer which does not match
    // the requirement set by user
    rates.sort(function (
      a: any,
      b: any
    ) {
      let totalA = 0,
        totalB = 0;

      _.each(a.costs, (data) => (totalA += data.rate));
      _.each(b.costs, (data) => (totalB += data.rate));

      return totalA === totalB ? 0 : (totalA > totalB ? 1 : -1);
    });

    if (selectedProductRates !== null) {
      rates = _.union([selectedProductRates], rates);
    }

    // There is non need to return ALL results, only a limited quantity needs to be returned
    return rates;
  }

  private calculateTransac(simulation: any) {
    const self = this;
    let rates = [];
    // console.log(simulation);
    _.each(this.products.transac, product => {
      // console.log(
      //   'calculateTransac ',
      //   this.checkAdvancedFields(product, simulation),
      //   this.checkProductForSimulation(product, simulation)
      // );
      if (
        this.checkProductForSimulation(product, simulation)
        && (
          // Advanced fields check only for simulation wihtout targeted product
          simulation.model_ref_sap !== undefined
          || this.checkAdvancedFields(product, simulation)
        )
      ) {
        // console.log('calculateTransac True', product, simulation);
        if (
          (simulation.vol_color > 0 && product.mono_color === 'COLOR') ||
          (simulation.vol_mono > 0 && simulation.vol_color === 0)
        ) {
          // Retreive basic data [distributor cost, simulation duration]
          const tmpRate = {
              product: product.ref_sap,
              type: 'TRANSAC',
              costs: [
                {
                  type: CostType.DISTRIBUTOR,
                  rate: product.distributor_price_france
                },
              ]
            },
            duration = simulation.duration;

          const consumableCost = this.manageTransacConsumableRates(
            product,
            simulation.vol_mono * duration,
            simulation.vol_color * duration
          );

          // Manage retrieval of warranty cost
          const warrantyCost = self.manageWarranty(product, duration);
          if (warrantyCost.rate > 0) {
            tmpRate.costs.push(warrantyCost);
          }

          if (consumableCost.rate >= 0) {
            tmpRate.costs.push(consumableCost);

            // If product == selected ref
            // console.log('calculateTransac CHECK', product.ref_sap, simulation.model_ref_sap);
            if (product.ref_sap === simulation.model_ref_sap) {
              rates = [tmpRate];
              return false;
            }

            rates.push(tmpRate);
          }

          // console.log('calculateTransac end', warrantyCost, tmpRate);
        }
      }
    });

    // console.log(rates);

    return rates;
    // Calcul des conso = big BS
    // tco =
    // (product.prix_cartouche_color * (sim.vol_color/product.capa_color) * product.mps_color)
    // + (product.prix_cartouche_mono * (sim.vol_mono/product.capa_mono) * product.mps_mono)
    // + product.prix_drum * (product.drum_capa / (sim.vol_color+sim.vol_mono))
    // + product.distributor_price_france
    // + warranty (inexistant sur transac ?)
  }

  private manageTransacConsumableRates(product: any, volumeMono: number, volumeColor: number) {
    // Kind reminder that there's no warranty in Transac simulation
    // Don't forgot to remove the STARTER from the simulation rates
    // Step 1 = Retrieve cost for CMYK toner/ink
    // Step 2 = Retrieve cost for WT BU DR
    // RAte of a TONER/INK is always the same for CMY.
    // Get only for one, then multiply by 3

    // --- Retrieve Waste cost of given product and previsional volume
    const wasteCost = this.calculateWasteConsumableRates(
      product,
      this.products.consumables,
      volumeMono,
      volumeColor
    );

    let consumableCost = wasteCost.rate;

    // --- Retrieve Correct ratio for ink/toner
    let ratioCMYK = this.ratio_toner;

    if (product.techno === 'InkJet') {
      ratioCMYK = this.ratio_ink;
    }

    // --- Retrieve ink/laser costs depending on previsional volume
    const starterCMY = product.tn_starter_cmyk * ratioCMYK,
      starterK = product.tn_starter_bk * ratioCMYK,
      capacityK = product.tn_bk_high_capacity_vol * ratioCMYK, // @Weird : high capacity ?
      capacityCMY = product.tn_cmy_high_capacity_vol * ratioCMYK;

    // Retrieve price of each ink/toner (Black / Color)
    let rateColor = 0,
      rateMono = 0;

    _.each(this.products.consumables, (consumable) => {
      if (rateMono === 0 || (volumeColor > 0 && rateColor === 0)) {
        if (
          consumable.ref_sap === product.tn_c_high_capacity ||
          consumable.ref_sap === product.tn_m_high_capacity ||
          consumable.ref_sap === product.tn_y_high_capacity
        ) {
          rateColor = _.round(consumable.distributor_price_france * 3, 2);
        } else if (consumable.ref_sap === product.tn_bk_name_high_capacity) {
          rateMono = consumable.distributor_price_france;
        }
      } else {
        return false;
      }
    });

    if (rateMono === 0 || (volumeColor > 0 && rateColor === 0)) {
      consumableCost = -1;
    } else {
      let finalMonoRate = 0,
      finalColorRate = 0;

      // (((Volume_Prev - Volume_MONO) / Volume_Mono) * Ratio_MONO) * Rate_MONO
      let realMonoQty = ((volumeMono + volumeColor) - starterK) / capacityK;
      if (realMonoQty % 1 > 0) {
        realMonoQty = Math.ceil(realMonoQty);
      }

      finalMonoRate = _.round(realMonoQty * rateMono, 2);
      if (finalMonoRate > 0) {
        consumableCost += finalMonoRate;
        wasteCost.details.push({
          'type': 'MONO',
          'ref': product.tn_bk_name_high_capacity,
          'rate': finalMonoRate,
          'volume': {
            'starter': starterK,
            'previsional': volumeMono + volumeColor,
          },
          'capacity': capacityK,
          'unit': rateMono,
          'qty':  Math.ceil(finalMonoRate / rateMono)
        });
      }

      let realColorQty = (volumeColor - starterCMY) / capacityCMY;
      if (realColorQty % 1 > 0) {
        realColorQty = Math.ceil(realColorQty);
      }

      finalColorRate = _.round(realColorQty * rateColor, 2);
      if (finalColorRate > 0) {
        consumableCost += finalColorRate;
        wasteCost.details.push({
          'type': 'COLOR',
          'ref': product.tn_c_high_capacity + ', ' + product.tn_m_high_capacity + ', ' + product.tn_y_high_capacity,
          'rate': finalColorRate,
          'volume': {
            'starter': starterCMY,
            'previsional': volumeColor,
          },
          'capacity': capacityCMY,
          'unit': rateColor,
          'qty': Math.ceil(finalColorRate / rateColor)
        });
      }
    }

    return {
      type: CostType.PREVISIONAL,
      rate: consumableCost,
      details: wasteCost.details
    };
  }

  // This will retrieve the rates of waste consummable (BU/WT/DR)
  private calculateWasteConsumableRates(product: any, consumables: Array <any> , volumeMono: number, volumeColor: number) {
    // console.log(product);
    const buName = product.bu_name,
      wtName = product.wt_name,
      drName = product.dr_name,
      volume = volumeMono + volumeColor,
      details = [];

    let rate = 0;

    // console.log('DETAILS', consumables, details, buName, wtName, drName, volume);
    // console.log(_.find(consumables, {
    //   'ref_sap': buName
    // }));
    // console.log(_.find(consumables, {
    //   'ref_sap': wtName
    // }));
    // console.log(_.find(consumables, {
    //   'ref_sap': drName
    // }));
    // COST PER Consummable accessory (BU, WT, DR)
    if (buName !== null && buName.length > 0) {
      const bu = _.find(consumables, {
        'ref_sap': buName
      });

      if (bu !== null && !_.isUndefined(bu)) {
        // GET The volume which isn't covered by supply provided at start
        const buCapacity = (product.bu_vol * this.ratio_bu);
        const volumeBU = volume - buCapacity;
        const payableVolume = Math.ceil(volumeBU / buCapacity);
        // console.log('BU', volumeBU, payableVolume);
        if (payableVolume > 0) {
          const tmpRate = payableVolume * bu.distributor_price_france;
          details.push({
            'type': 'WASTE_BU',
            'ref': bu.ref_sap,
            'rate': tmpRate,
            'volume': {
              'starter': buCapacity,
              'previsional': volume,
            },
            'capacity': buCapacity,
            'unit': bu.distributor_price_france,
            'qty': _.round( tmpRate / bu.distributor_price_france, 2)
          });
          rate += tmpRate;
        }
      }
    }

    if (wtName !== null && wtName.length > 0) {
      const wt = _.find(consumables, {
        'ref_sap': wtName
      });

      if (wt !== null && !_.isUndefined(wt)) {
        // GET The volume which isn't covered by supply provided at start
        const wtCapacity = product.wt_vol * this.ratio_wt;
        const volumeWT = volume - wtCapacity;
        const payableVolume = Math.ceil(volumeWT / wtCapacity);
        // console.log('WT', volumeWT, payableVolume);
        if (payableVolume > 0) {
          const tmpRate = payableVolume * wt.distributor_price_france;
          details.push({
            'type': 'WASTE_WT',
            'ref': wt.ref_sap,
            'rate': tmpRate,
            'volume': {
              'starter': wtCapacity,
              'previsional': volume,
            },
            'capacity': wtCapacity,
            'unit': wt.distributor_price_france,
            'qty': _.round(tmpRate / wt.distributor_price_france, 2)
          });
          rate += tmpRate;
        }
      }
    }

    if (drName !== null && drName.length > 0) {
      const dr = _.find(consumables, {
        'ref_sap': drName
      });

      if (dr !== null && !_.isUndefined(dr)) {
        // GET The volume which isn't covered by supply provided at start
        const drCapacity = product.dr_vol * this.ratio_drum;
        const volumeDR = volume - drCapacity;
        const payableVolume = Math.ceil(volumeDR / drCapacity);
        // console.log('DR', volumeDR, payableVolume);
        if (payableVolume > 0) {
          const tmpRate = payableVolume * dr.distributor_price_france;
          details.push({
            'type': 'WASTE_DR',
            'ref': dr.ref_sap,
            'rate': tmpRate,
            'volume': {
              'starter': drCapacity,
              'previsional': volume,
            },
            'capacity': drCapacity,
            'unit': dr.distributor_price_france,
            'qty':  _.round(tmpRate / dr.distributor_price_france, 2)
          });

          rate += tmpRate;
        }
      }
    }

    return {
      rate,
      details
    };
  }

  private manageWarranty(product: any, duration: number) {
    const warrantyCost = {
      type: CostType.WARRANTY,
      rate: 0
    };
    switch (duration) {
      case 36:
        warrantyCost.rate = _.get(product, 'warranties.36');
        break;
      case 48:
        warrantyCost.rate = _.get(product, 'warranties.48');
        break;
      case 60:
        warrantyCost.rate = _.get(product, 'warranties.60');
        break;
    }

    // console.log('manageWarranty', product, warrantyCost);
    return warrantyCost;
  }

  private sortRates(rates: Array<Rate>, qty: number|0, ref_sap: string|undefined) {
    // Sort RATES by total price
    // console.log(rates, qty, ref_sap);
    rates.sort(function (
      a: Rate,
      b: Rate
    ) {
      let totalA = 0,
          totalB = 0;

      _.each(a.costs, (data) => (totalA += data.rate));
      _.each(b.costs, (data) => (totalB += data.rate));

      return totalA === totalB ? 0 : (totalA > totalB ? 1 : -1);
    });

    let sliced = _.slice(rates, 0, qty);
    if (ref_sap !== undefined) {
      const productRef = _.find(rates, {product: ref_sap});
      if (productRef !== undefined) {
        sliced =  _.slice(_.union([productRef], sliced), 0, qty);
      }
    }
    console.log('sortRates', rates, sliced);
    return sliced;
  }

  private checkProductForSimulation(product: any, simulation: any) {
    // console.log(
    //   'checkProductForSimulation',
    //   product.ref_sap,
    //   simulation.format, product.format, simulation.format !== product.format,
    //   simulation.need, product.besoin , simulation.need !== product.besoin ,
    //   simulation.techno, product.techno , simulation.techno !== product.techno ,
    //   simulation.connectivity, product.connectivites , simulation.connectivity !== product.connectivites ,
    //   simulation.mono_color, product.mono_color,  simulation.mono_color !== product.mono_color
    // );

    // CONTRAINTE DE VOLUME SUR LES MACHINES
    const totalPV = simulation.vol_color + simulation.vol_mono;
    // console.log(
    //   'PREV VOL',
    //   simulation,
    //   totalPV, product.pv_max, product.pv_min,
    //   product.pv_max !== null, totalPV > product.pv_max,
    //   product.pv_min !== null, totalPV < product.pv_min
    // );
    if ((product.pv_max !== null && totalPV > product.pv_max) || (product.pv_min !== null && totalPV < product.pv_min)) {
      return false;
    }

    if (!this.checkNonExclusiveField(simulation.format, product.format, this.format)) {
      return false;
    } else if (simulation.need !== null && !this.checkNonExclusiveField(simulation.need, product.besoin, this.needs)) {
      return false;
    } else if (simulation.techno !== null && simulation.techno !== product.techno) {
      return false;
    } else if (
      simulation.connectivity !== null
      && !this.checkNonExclusiveField(simulation.connectivity, product.connectivites, this.connectivity)
    ) {
      return false;
    } else if (simulation.mono_color !== null && simulation.mono_color !== product.mono_color) {
      return false;
    }

    return true;
  }

  private checkNonExclusiveField(simulationValue: string, productValue: string, fields: any) {
    // console.log(
    //     'checkNonExclusiveField',
    //     simulationValue,
    //     productValue,
    //     fields,
    //     _.get(fields, simulationValue),
    //     _.get(_.get(fields, simulationValue), productValue)
    // );
    // Get the accepted values for the requested field and check with productValue
    return _.get(_.get(fields, simulationValue), productValue) === true;
  }

  private checkAdvancedFields(
    product: any,
    simulation: any
  ) {
    // console.log(
    //   'checkAdvancedFields',
    //   product.ref_sap,
    //   simulation.sizing.width, product.sizing.width, simulation.sizing.width < product.sizing.width,
    //   simulation.sizing.height, product.sizing.height, simulation.sizing.height < product.sizing.height,
    //   simulation.sizing.depth, product.sizing.depth, simulation.sizing.depth < product.sizing.depth,
    //   simulation.ppm.mono, product.ppm.mono, simulation.ppm.mono < product.ppm.mono,
    //   simulation.ppm.color, product.ppm.color, simulation.ppm.color < product.ppm.color
    // );
    // if true, product match simulation advanced fields requirements
    if (
      simulation.sizing.width > 0
      && simulation.sizing.width < product.sizing.width
    ) {
      return false;
    } else if (
      simulation.sizing.height > 0
      && simulation.sizing.height < product.sizing.height
    ) {
      return false;
    } else if (
      simulation.sizing.depth > 0
      && simulation.sizing.depth < product.sizing.depth
    ) {
      return false;
    }

    if (
      simulation.ppm.mono > 0
      && simulation.ppm.mono > product.ppm.mono
    ) {
      return false;
    } else if (
      simulation.ppm.color > 0
      && simulation.ppm.color > product.ppm.color
    ) {
      return false;
    }

    return true;
  }
}
