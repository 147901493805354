import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { HelpPageComponent } from '../../../help-page/help-page.component';

import { SimulatorService } from '../../../services/simulator.service';
import { ApiService } from '../../../services/api.service';
import { User } from '../../class/user.class';
import { Utils } from '../../class/utils.class';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  public isConnected = true;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public user: User,
    public utils: Utils,
    private connectionService: ConnectionService,
    private simulatorService: SimulatorService,
    private api: ApiService
  ) {
    this.isConnected = navigator.onLine;
    this.connectionService
      .monitor()
      .subscribe(isConnected => {
        this.isConnected = isConnected;
      });
  }

  ngOnInit() { }

  resetSimulation() {
    this.simulatorService.resetSimulation();
  }

  logout() {
    // Delete user session
    this.api.logout();
    // Clear localStorage et redirection home
    localStorage.clear();
    //alert('Déconnexion en cours...');
    this.router.navigateByUrl('/auth');
  }

  open() {
    const modalRef = this.modalService.open(HelpPageComponent, {size: 'lg'});
    modalRef.componentInstance.name = 'Help';
  }
}
