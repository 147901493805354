import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SimulatorService } from '../../../services/simulator.service';
import { Utils } from '../../../shared/class/utils.class';
import { ChartComponent } from 'angular2-chartjs';
import { ConnectionService } from 'ng-connection-service';
import 'chartjs-plugin-labels';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-final-choice',
  templateUrl: './final-choice.component.html',
  styleUrls: ['./final-choice.component.scss']
})
export class FinalChoiceComponent implements OnInit {
  public costs: any;
  public simulation: any;
  public product: any;
  public currentModal: any;
  public functions = [];
  public options = [];
  public totalCost: number;
  public prevCostDetails = [];
  public groupedCosts: any;
  public type: string;
  public simulationMail: string;
  public simulationName: string;
  console = console;

  @ViewChild(ChartComponent) chart: ChartComponent;

  // DOUGNUT DATA
  public doughnutData = {
    labels: [
      'Conso', 'Garantie', 'Hardware', 'Fonctions+', 'Options'
    ],
    datasets: []
  };
  public doughnutType = 'pie';
  public doughnutOptions = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true
      },
      onClick: function(e, legendItem) { }
    },
    responsive: true,
    maintainAspectRatio: false,
    events: [
      'click'
    ],
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data['labels'][tooltipItem[0]['index']];
        },
        label: function (tooltipItem, data) {
          return '' + data['datasets'][0]['data'][tooltipItem['index']] + ' €';
        },
        afterLabel: function (tooltipItem, data) {
          var dataset = data['datasets'][0];
          let total = 0;
          _.each(dataset.data, (val) => (total += val));
          var percent = Math.round((dataset.data[tooltipItem['index']] / total) * 100);
          return 'Soit ' + percent + '% du prix total';
        },
      },
      titleFontSize: 16,
      titleFontColor: '#FFFFFF',
      bodyFontSize: 14,
      displayColors: true,
    },
    plugins: {
      labels: [{
        fontColor: '#fff',
        render: 'percentage',
        fontSize: 14,
        textShadow: true,
        shadowColor: 'rgba(0,0,0,0.7)',
        shadowBlur: 1,
        shadowOffsetX: 1,
        shadowOffsetY: 1,
      }]
    }
  };

  constructor(
    private apiService: ApiService,
    private simulatorService: SimulatorService,
    private utils: Utils,
    private connectionService: ConnectionService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    if (this.simulatorService.currentSimulation === undefined) {
      this.utils.goToHome();
    }

    if (this.simulatorService.getSelectedProduct() === undefined) {
      this.utils.goToResultats();
    }
  }

  ngOnInit() {
    this.simulation = this.simulatorService.currentSimulation.data;
    // console.log(this.simulation);
    this.product = this.simulatorService.getSelectedProduct();
    this.type = this.simulatorService.getSelectedProductType();
    this.costs = this.simulatorService.getSelectedProductCost();

    const functions = _.filter(this.costs, {'type': 'FUNCTIONS'});
    const functionsData = this.simulatorService.getFunctionsForSelectedRef();
    const options = _.filter(this.costs, {'type': 'OPTIONS'});
    const optionsData = this.simulatorService.getOptionsForSelectedRef();

    options.forEach((option: any) => {
      const givenOption = _.filter(optionsData, {'ref_sap': option.ref_sap})[0];
      this.options.push({
        rate: option.rate,
        ref: givenOption.ref_sap,
        title: givenOption.title,
        description: givenOption.designation,
        quantity: option.rate / givenOption.distributor_price_france,
      });
    });
    functions.forEach((func: any) => {
      const givenFunction = _.filter(functionsData, {'ref_sap': func.ref_sap})[0];
      this.functions.push({
        rate: func.rate,
        ref: givenFunction.ref_sap,
        title: givenFunction.title,
        description: givenFunction.designation,
        quantity: func.rate / givenFunction.distributor_price_france,
      });
    });

    this.prevCostDetails = _.get(_.filter(this.costs, {'type': 'PREVISIONAL'})[0], 'details');
    // console.log('prevCostDetails', this.prevCostDetails);
    this.totalCost = 0;
    this.groupedCosts = {
      previsional: 0,
      functions: 0,
      warranty: 0,
      options: 0,
      base: 0,
    };

    // console.log(this.costs);

    const self = this;

    _.each(this.costs, (cost) => {
      self.totalCost += cost.rate;
      switch (cost.type) {
        case 'PREVISIONAL':
          self.groupedCosts.previsional += cost.rate;
          break;
        case 'WARRANTY':
          self.groupedCosts.warranty += cost.rate;
          break;
        case 'DISTRIBUTOR':
          self.groupedCosts.base += cost.rate;
          break;
        case 'FUNCTIONS':
          self.groupedCosts.functions += cost.rate;
          break;
        case 'OPTIONS':
          self.groupedCosts.options += cost.rate;
          break;
      }
    });

    this.totalCost = Math.round(this.totalCost * 100) / 100;
    this.doughnutData.datasets = [{
      data: [
        Math.round(this.groupedCosts.previsional * 100) / 100,
        Math.round(this.groupedCosts.warranty * 100) / 100,
        Math.round(this.groupedCosts.base * 100) / 100,
        Math.round(this.groupedCosts.functions * 100) / 100,
        Math.round(this.groupedCosts.options * 100) / 100,
      ],
      backgroundColor: [
        '#EB7200',
        '#00bef1',
        '#a5dd26',
        '#6F2796',
        '#eba200',
      ]
    }];

    var elem = $("#fixed_share");
    elem.addClass("_fixed");
    let beurk = false;
    var fixed_share = document.getElementById('fixed_share');
    window.onscroll = function() {
      if (!beurk) {
        beurk = true;
        fixed_share.className = checkVisible(fixed_share) ? 'not_fixed' : '_fixed';
      }
    };

    function checkVisible(elm) {
      const rect = elm.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }
  }

  onSubmit(f: NgForm) {
    if (f.invalid === false) {
      this.shareSimulationByEmail();
    } else if (this.simulationMail.length === 0) {
      this.toastr.error('L\'email est un champ obligatoire.');
    } else if (this.simulationMail.match(/@/)) {
      this.toastr.error('L\'email ne doit pas comporter d\'arobase (@).');
    } else if (this.simulationName.length === 0) {
      this.toastr.error('Le nom de la simulation est obligatoire.');
    }
  }

  shareSimulationByEmail() {
    const that = this;
    this.simulation.name = this.simulationName;
    this.simulation.target_email = this.simulationMail;

    // console.log("SIM NAME3", this.simulationName);
    this.apiService.shareSimulationToCurrentUser(
      this.simulation,
      false
    ).subscribe(
      (success) => {
        that.toastr.success('La simulation a été envoyée par mail');
        this.currentModal.close();
      },
      (error) => {
        if (navigator.onLine) {
          that.toastr.error('Une erreur est survenue, merci de réessayer');
        } else {
          that.toastr.warning('Votre réseau est instable, l\'email sera envoyé lorsque votre réseau sera stable.');
        }
        this.currentModal.close();
      }
    );
  }

  open(content) {
    this.currentModal = this.modalService.open(
      content,
      {
        centered: true
      }
    );
  }

  getTotalCost() {
      let total = 0;

      _.each(this.prevCostDetails, function(details) {
          total += details.rate;
      });

      return Math.ceil(total * 100) / 100;
  }

  resetSimulation() {
    this.simulatorService.resetSimulation();
  }
}
