import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

import { ApiService } from './api.service';
import { ReplayerService } from './replayer.service';
import { SimulatorService } from './simulator.service';

@Injectable({
    providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private readonly api: ApiService,
        private readonly router: Router,
        private readonly replayer: ReplayerService,
        private readonly simulationService: SimulatorService
    ) { }

    /**
     * @param HttpRequest<any> request - The intercepted request
     * @param HttpHandler next - The next interceptor in the pipeline
     * @return Observable<HttpEvent<any>>
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            // add error handling
            .pipe(
                catchError(
                    (error: any, caught: Observable<HttpEvent<any>>) => {
                        if (error.status === 401) {
                            this.handleAuthError();
                            // if you've caught / handled the error, you don't
                            // want to rethrow it unless you also want
                            // downstream consumers to have to handle it as
                            // well.
                            return of(error);
                        } else if (error.status === 504 || error.status === 0) {
                            if (request.body && !request.body.isRetry) {
                                if (request.url.match(/api\/simulation$/)) {
                                    this.replayer.add(this.simulationService.currentSimulation.data, 'POST');
                                } else if (request.url.match(/api\/simulation\/share$/)) {
                                    this.replayer.add(this.simulationService.currentSimulation.data, 'SHARE');
                                }
                            }
                            console.log('503 or 0 request response detected.');
                        }

                        throw error;
                    }
                ),
            );
    }

    /**
     * Handle API authentication errors.
     */
    private handleAuthError() {
        // clear stored credentials; they're invalid
        localStorage.clear();
        // navigate back to the login page
        this.router.navigateByUrl('/auth');
    }
}