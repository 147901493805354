import {Injectable} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';


@Injectable()
export class User {

  user: any;

  constructor(
    private Router: Router
  ) {
  }


  // -- FUNCTIONS

  // -- REDIRECT

  /**
   * Redirect to login
   */
  redirectLogoutUser() {
    if (!this.isLogin()) {
      this.Router.navigateByUrl('login');
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check user login
   */
  isLogin() {
    if (localStorage.getItem('currentUser') === null) {
      return false;
    }
    return true;
  }


  // -- USER

  /**
   * Get user
   */
  getUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getUserToken() {
    return JSON.parse(localStorage.getItem('currentToken'));
  }

  /**
   * Set user
   * @param {[type]} user [category pdf]

   */
  setUser(login, token) {
    localStorage.setItem('currentUser', login);
    localStorage.setItem('currentToken', token);

    if (localStorage) {
      const user = localStorage.getItem('currentUser');

      if (user != null) {
        return localStorage.getItem('currentUser');
      }
    }

  }

}
