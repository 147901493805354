import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { Utils } from '../shared/class/utils.class';
import { Variables } from '../shared/config/variables';
import { User } from '../shared/class/user.class';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-appareil-view',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [Utils]
})
export class RegisterComponent implements OnInit {

  private errorChampsEmpty = Variables.errorChampsEmpty;
  private success = false;
  service: string = '';
  prenom: string = '';
  nom: string = '';
  telephone: string = '';
  email: string = '';
  message: string = '';

  validService: boolean = true;
  validPrenom: boolean = true;
  validNom: boolean = true;
  validTelephone: boolean = true;
  validEmail: boolean = true;

  HasError: boolean = false;

  constructor(@Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    public utils: Utils,
    private user: User,
    private api: ApiService
  ) {
    if (this.user.isLogin()) {
      this.utils.goToHome();
    }
  }

  ngOnInit() {
    this.document.body.classList.add('hide_nav');
  }

  submitRegister() {
    if (
      this.service === ''
      && this.prenom === ''
      && this.nom === ''
      && this.telephone === ''
      && this.email === ''
     ) {
      this.validService = false;
      this.validPrenom = false;
      this.validNom = false;
      this.validTelephone = false;
      this.validEmail = false;
    } else {
      this.api.loadIn();
      this.api
        .postRequest(this.api.urls.create, {
          'email': this.email,
          'firstname': this.prenom,
          'lastname': this.nom,
          'service': this.service,
          'phone': this.telephone,
          'comment': this.message
        })
        .subscribe(
          () => {
            this.utils.goToRegisterSuccess();
            this.api.loadOff();
          }
        );
    }
    return false;
  }

  /**
 * [selectedItem delete error]
 */
  selectedItem(value) {
    switch (value) {
      case 'service':
        this.validService = true;
        break;
      case 'prenom':
        this.validPrenom = true;
        break;
      case 'nom':
        this.validNom = true;
        break;
      case 'telephone':
        this.validTelephone = true;
        break;
      case 'email':
        this.validEmail = true;
        break;
      default:
        break;
    }
  }

}
