import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular2-chartjs';
import { SlickModule } from 'ngx-slick';

import { ApiService } from './services/api.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthComponent } from './auth/auth.component';
import { RegisterComponent } from './register/register.component';
import { Routes, RouterModule } from '@angular/router';

import { User } from './shared/class/user.class';
import { Products } from './shared/class/products.class';
import { Utils } from './shared/class/utils.class';

import { HeaderComponent } from './shared/components/header/header.component';
import { GobackComponent } from './shared/components/goback/goback.component';
import { PasswordlostComponent } from './passwordlost/passwordlost.component';
import { RegisterSuccessComponent } from './register/success/success.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ResultsPageComponent } from './home-page/results-page/results-page.component';
import { SinglePageComponent } from './home-page/results-page/single-page/single-page.component';
import { GraphiqueProductsComponent } from './home-page/results-page/graphique-products/graphique-products.component';
import { AddOptionsComponent } from './home-page/results-page/add-options/add-options.component';
import { FinalChoiceComponent } from './home-page/results-page/final-choice/final-choice.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FooterCgvComponent } from './shared/components/footer-cgv/footer-cgv.component';
import { CgvComponent } from './cms/cgv/cgv.component';
import { RequestInterceptor } from './services/interceptor.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ProductPreviewComponent } from './home-page/results-page/product-preview/product-preview.component';
import {AddFunctionsComponent} from './home-page/results-page/add-functions/add-functions.component';
import { ProductLightComponent } from './home-page/results-page/product-light/product-light.component';
import { CardFunctionComponent } from './home-page/results-page/add-functions/card-function/card-function.component';

const appRoutes: Routes = [
  { path: '', component: AuthComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'passwordlost', component: PasswordlostComponent },
  { path: 'register-success', component: RegisterSuccessComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'resultats', component: ResultsPageComponent },
  { path: 'graphiques', component: GraphiqueProductsComponent },
  { path: 'ajouter-options', component: AddOptionsComponent },
  { path: 'ajouter-fonctions', component: AddFunctionsComponent },
  { path: 'choix-final', component: FinalChoiceComponent },
  { path: 'help-page', component: HelpPageComponent },
  { path: 'cgv', component: CgvComponent },
];

@NgModule({
  declarations: [
    HeaderComponent,
    GobackComponent,
    AppComponent,
    AuthComponent,
    RegisterComponent,
    PasswordlostComponent,
    RegisterSuccessComponent,
    HomePageComponent,
    ResultsPageComponent,
    SinglePageComponent,
    GraphiqueProductsComponent,
    AddOptionsComponent,
    AddFunctionsComponent,
    FinalChoiceComponent,
    HelpPageComponent,
    FooterCgvComponent,
    CgvComponent,
    ProductPreviewComponent,
    ProductLightComponent,
    CardFunctionComponent
  ],
  imports: [
    ChartModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'top'}),
    NgbModule,
    SlickModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgSelectModule,
    BrowserAnimationsModule, // required animations module for toastr
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    ApiService,
    User,
    Utils,
    Products,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
