// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API_URL = 'https://brother-back.sinfin.dev/api/';

export const environment = {
  production: false,
  token: 'Bearer bAt2rs9WfNVSHdocqSHDDEoBYSWmKyBc',
  api_url: API_URL,
  rest: {
    'create': API_URL + 'user',
    'log_in': API_URL + 'user/login',
    'log_out': API_URL + 'user/logout',
    'forgot_password': API_URL + 'user/password/forgot',
    'get_products': API_URL + 'products',
    'post_simulation': API_URL + 'simulation',
    'share_simulation': API_URL + 'simulation/share'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
