import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {SimulatorService} from '../../../services/simulator.service';
import {CalculatorService} from '../../../services/calculator.service';
import {ApiService} from '../../../services/api.service';

import {Utils} from '../../../shared/class/utils.class';
import {FormBuilder, FormGroup, FormArray, NgForm} from '@angular/forms';
import * as _ from 'lodash';

const twoTwoOne = {
    '250': 2,
    '500': 2,
    'both': {
        '250': 1,
        '500': 1,
    }
};

const threeTwoOne = {
    '250': 3,
    '500': 2,
    'both': {
        '250': 1,
        '500': 1,
    }
};
const threeZeroZero = {
    '250': 3,
    '500': 0,
    'both': {
        '250': 0,
        '500': 0,
    }
};

const OPTIONS_MATRIX = {
    'HL-L5000D': twoTwoOne,
    'HL-L5100DN': twoTwoOne,
    'HL-L5200DW': twoTwoOne,
    'DCP-L5500DN': twoTwoOne,
    'MFC-L5700DN': twoTwoOne,
    'MFC-L5750DW': twoTwoOne,
    'DCP-L6600DW': twoTwoOne,
    'MFC-L6800DW': twoTwoOne,
    'MFC-L6900DW': twoTwoOne,
    'DCP-L8410CDW': threeZeroZero,
    'MFC-L8690CDW': threeZeroZero,
    'HL-L8260CDW': threeZeroZero,
    'HL-L6250DN': threeTwoOne,
    'HL-L6300DW': threeTwoOne,
    'HL-L6400DW': threeTwoOne,
    'MFC-L8900CDW': threeTwoOne,
    'HL-L8360CDW': threeTwoOne,
    'HL-L9310CDW': threeTwoOne,
    'MFC-L9570CDW': threeTwoOne,
};

@Component({
    selector: 'app-add-options',
    templateUrl: './add-options.component.html',
    styleUrls: ['./add-options.component.scss']
})
export class AddOptionsComponent implements OnInit {
    noOptions = true;

    public myForm: FormGroup;
    public currentProduct: any;
    public options: {
        bac_250f: any,
        bac_500f: any,
        meuble_support: any,
        meuble_bacs: any,
        complement_meuble_bac: any,
        chargeur_boite_aux_lettres: any,
    };
    public sortedOptions: {
        bacs: {
            small: any;
            big: any;
        },
        tower: {
            tray: any;
            connector: any;
        },
        support: any;
    };

    public optionMatrix: any;
    console = console;

    public bac250 = 0;
    public bac500 = 0;
    public bac250Qty = [1];
    public bac500Qty = [1];
    public towerTray = false;
    public towerTrayOptions = false;
    public meubleSupport = false;
    public chargeurBoiteLettre = false;

    constructor(
        private _fb: FormBuilder,
        private apiService: ApiService,
        private simulatorService: SimulatorService,
        private calculatorService: CalculatorService,
        private utils: Utils
    ) {
    }

    ngOnInit() {
        // console.log(this.simulatorService.currentSimulation, this.noFunctions, this.noOptions);
        if (this.simulatorService.currentSimulation === undefined) {
            this.utils.goToHome();
            return false;
        }

        this.currentProduct = this.simulatorService.getSelectedProduct();

        this.optionMatrix = _.get(OPTIONS_MATRIX, this.currentProduct.ref_sap);

        this.options = this.simulatorService.getOptionsForSelectedRef();

        _.each(this.options, opt => {
            if (opt !== undefined) {
                this.noOptions = false;
            }
        });

        if (this.noOptions) {
            this.utils.goToFinalView();
            return false;
        }

        this.initOptions();
    }

    initOptions() {
        const small = _.get(this.optionMatrix, '250'),
            big = _.get(this.optionMatrix, '500');

        // +1 cause last number of range is not inscluded in lodash range function
        this.bac250Qty = _.range(1, small + 1);
        this.bac500Qty = _.range(1, big + 1);
    }

    save(form: NgForm) {
        const selectedOptions = [];

        if (this.bac250 > 0) {
            for (let i = 0; i < this.bac250; i++) {
                selectedOptions.push(this.options.bac_250f.ref_sap);
            }
        }
        if (this.bac500 > 0) {
            for (let i = 0; i < this.bac500; i++) {
                selectedOptions.push(this.options.bac_500f.ref_sap);
            }
        }
        if (this.meubleSupport) {
            selectedOptions.push(this.options.meuble_support.ref_sap);
        }
        if (this.chargeurBoiteLettre) {
            selectedOptions.push(this.options.chargeur_boite_aux_lettres.ref_sap);
        }
        if (this.towerTray) {
            selectedOptions.push(this.options.meuble_bacs.ref_sap);
            if (this.towerTray && this.options.complement_meuble_bac !== undefined) {
                selectedOptions.push(this.options.complement_meuble_bac.ref_sap);
            }
        }

        this.simulatorService.cleanPluginsCosts('OPTIONS');
        if (selectedOptions.length > 0) {
            const optionCosts = this.calculatorService.getOptionsCosts(
                selectedOptions
            );

            this.simulatorService.addPluginsCosts(optionCosts);

            this
                .apiService
                .postSimulation(
                    this.simulatorService.currentSimulation.data,
                    false
                )
                .subscribe(
                    () => (this.utils.goToFunctions()),
                    () => (this.utils.goToFunctions())
                );
        } else {
            this.utils.goToFunctions();
        }
    }

    optionsChange(f: NgForm) {
        this.initOptions();

        if (this.bac250 > 0) {
            if (this.bac250 > _.get(this.optionMatrix.both, '250')) {
                this.bac500Qty = [];
            } else {
                this.bac500Qty = _.range(1, _.get(this.optionMatrix.both, '500') + 1);
            }
        }
        if (this.bac500 > 0) {
            if (this.bac500 > _.get(this.optionMatrix.both, '500')) {
                this.bac250Qty = [];
            } else {
                this.bac250Qty = _.range(1, _.get(this.optionMatrix.both, '250') + 1);
            }
        }
    }
}
