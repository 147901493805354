import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-function',
  templateUrl: './card-function.component.html',
  styleUrls: ['./card-function.component.scss']
})
export class CardFunctionComponent implements OnInit {
  @Input() func: any;
  @Input() selectFunc: any;
  @Input() unselectFunc: any;

  public select = false;
  public class = '';

  constructor() { }

  ngOnInit() { }

  toggleSelect() {
    this.select = !this.select;
    if (this.select) {
      this.selectFunc(this.func.ref_sap);
      this.class = 'selected';
    } else {
      this.unselectFunc(this.func.ref_sap);
      this.class = '';
    }
  }
}
