import {Component, OnInit} from '@angular/core';
import {SimulatorService} from '../../../services/simulator.service';

@Component({
    selector: 'app-product-light',
    templateUrl: './product-light.component.html',
    styleUrls: ['./product-light.component.scss']
})
export class ProductLightComponent implements OnInit {
    public simulation: any;
    public product: any;

    constructor(
        private simulatorService: SimulatorService
    ) {
    }

    ngOnInit() {
        if (!this.simulatorService.currentSimulation) {
            return;
        }

        this.simulation = this.simulatorService.currentSimulation.data;
        this.product = this.simulatorService.getSelectedProduct();
    }

    isShow = true;

    toggleDisplay() {
        this.isShow = !this.isShow;
    }
}
