import {Injectable} from '@angular/core';

import {v4 as uuid} from 'uuid';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import * as _ from 'lodash';
import {ApiService} from './api.service';
import {ConnectionService} from 'ng-connection-service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class ReplayerService {
    private queue: Array<any>;
    private cpt = 0;

    constructor(
        private api: ApiService,
        private connectionService: ConnectionService,
        private toastr: ToastrService
    ) {
        this.queue = [];
        this.loadQueue();
        this.saveQueue();
    }

    public add(requestBody: any, type: 'POST' | 'SHARE') {
        if (!_.find(this.queue, requestBody)) {
            this.queue.push({requestBody, type, uuid: uuid()});
            this.saveQueue();
        }
    }

    public resetQueue(request: any) {
        this.queue = [];
        this.saveQueue();
    }

    public initReplay() {
        this.replayHead();
    }

    public toastUser() {
        if (this.cpt > 0) {
            this.toastr.info(this.cpt + ' requètes ont été synchronisés.');
            this.cpt = 0;
        }
    }

    public replayHead() {
        if (this.queue.length) {
            const head = _.head(this.queue);
            if (this.replayOne(head)) {
                this.cpt++;
                this.removeFromQueue(head);

                return true;
            }
        }

        return false;
    }

    public replayOne(request: any) {
        let response;
        const that = this;

        if (request.type === 'POST') {
            response = this.api.postSimulation(request.requestBody, true).subscribe(
                (data) => (
                    that.removeFromQueue(request)
                ),
                (error) => (console.log(error)),
                () => {
                    if (!that.replayHead()) {
                        that.toastUser();
                    }
                }
            );
        } else if (request.type === 'SHARE') {
            response = this.api.shareSimulationToCurrentUser(request.requestBody, true).subscribe(
                (data) => (
                    that.removeFromQueue(request)
                ),
                (error) => (console.log(error)),
                () => {
                    if (!that.replayHead()) {
                        that.toastUser();
                    }
                }
            );
        }

        return true;
    }

    private removeFromQueue(request) {
        const banan = this.queue;
        // console.log('removeFromQueue - A', request, banan, _.find(this.queue, request));
        _.remove(this.queue, request);
        // console.log('removeFromQueue - B', banan);
        this.saveQueue();
    }

    private loadQueue() {
        const queries = localStorage.getItem('requests');
        if (queries && queries.length) {
            this.queue = JSON.parse(queries);
        }
    }

    private saveQueue() {
        localStorage.setItem('requests', JSON.stringify(this.queue));
    }
}
