import {Injectable} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class Products {
  public refs: Array<string>;
  public mps: Array<any>;
  public transac: Array<any>;
  public options: Array<any>;
  public functions: Array<any>;
  public consumables: Array<any>;

  constructor(
    private Router: Router
  ) {
    this.refs = [];
    this.mps = [];
    this.transac = [];
    this.options = [];
    this.functions = [];
  }

  // Return a product by REF
  getProductByRef(refSap: string) {
    if (refSap === undefined || refSap === null) {
      return null;
    }
    return _.find(this.transac, ['ref_sap', refSap]);
  }

  /**
   * Return a Function for the given ref
   * @param refSap
   */
  getFunctionByRef(refSap: string) {
    return _.find(this.functions, ['ref_sap', refSap]);
  }

  /**
   * Return an object filled by the different option for a given printer
   * @param refSap
   */
  getFunctionsByProductRef(refSap: string) {
    const product = _.find(this.transac, ['ref_sap', refSap]);

    return {
      bcu: product.functions.bcu ? _.find(this.functions, ['ref_sap', 'BCU']) : false,
      sp: _.get(product.functions, 'sp+') ? _.find(this.functions, ['ref_sap', 'SP+']) : false,
      bp: _.get(product.functions, 'bp+') ? _.find(this.functions, ['ref_sap', 'BP+']) : false,
      cui: product.functions.cui ? _.find(this.functions, ['ref_sap', 'CUI']) : false,
      os: product.functions.os ? _.find(this.functions, ['ref_sap', 'OS']) : false,
      rh: product.functions.rh ? _.find(this.functions, ['ref_sap', 'RH']) : false,
      rp: product.functions.rp ? _.find(this.functions, ['ref_sap', 'RP']) : false,
      tcp: product.functions.tcp ? _.find(this.functions, ['ref_sap', 'TCP']) : false,
    };
  }

  /**
   * Return an Option for the given ref
   * @param refSap
   */
  getOptionByRef(refSap: string) {
    return _.find(this.options, ['ref_sap', refSap]);
  }

  /**
   * Return an object filled by the different function for a given printer
   * @param refSap
   */
  getOptionsByProductRef(refSap: string) {
    const product = _.find(this.transac, ['ref_sap', refSap]);

    return {
      bac_250f: _.find(this.options, ['ref_sap', product.options.bac_250f]),
      bac_500f: _.find(this.options, ['ref_sap', product.options.bac_500f]),
      meuble_support: _.find(this.options, ['ref_sap', product.options.meuble_support]),
      meuble_bacs: _.find(this.options, ['ref_sap', product.options.meuble_bacs]),
      complement_meuble_bac: _.find(this.options, ['ref_sap', product.options.complement_meuble_bac]),
      chargeur_boite_aux_lettres: _.find(this.options, ['ref_sap', product.options.chargeur_boite_aux_lettres]),
    };
  }

  parseProducts(products) {
    // -- Get and Parse A list of SAP reference of each available products.
    // -- Kind reminder that every MPS reflects a Transac but all Transac are not reflected by a MPS
    const tmpRef = [];
    _.forEach(products.products.transac, function(product) {
      tmpRef.push(product.ref_sap);
    });

    this.refs = _.sortBy(_.uniq(tmpRef));
    this.options = _.sortBy(products.options, ['ref_sap']);
    this.functions = _.sortBy(products.functions, ['ref_sap']);

    this.consumables = _.sortBy(products.consumables, ['ref_sap']);

    this.mps = _.sortBy(products.products.mps, ['ref_sap']);
    this.transac = _.sortBy(products.products.transac, ['ref_sap']);
  }
}
