import { Component, OnInit, Input, NgModule, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import 'chartjs-plugin-zoom';
import { SimulatorService } from '../../../services/simulator.service';
import { Utils } from '../../../shared/class/utils.class';
import { ChartComponent } from 'angular2-chartjs';

@Component({
  selector: 'app-graphique-products',
  templateUrl: './graphique-products.component.html',
  styleUrls: ['./graphique-products.component.scss']
})

export class GraphiqueProductsComponent implements OnInit {

  @ViewChild(ChartComponent) chart: ChartComponent;

  public groupedByMonth = 6;

  // DOUGNUT DATA
  public lineData = {
    datasets: [],
    labels: []
  };
  public lineType = 'line';
  public lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true
      }
    },
    title: {
      //display: true,
      display: false,
      text: 'Graphique comparatif'
    },
    scales: {
      xAxes: [{
        /* type: 'time', */
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Mois'
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: false
        },
        scaleLabel: {
          display: true,
          labelString: 'Prix'
        }
      }]
    },
    devicePixelRatio: 2,
    elements: {
      line: {
        fill: false
      }
    },
    pan: {
      enabled: true,
      mode: "xy",
      speed: 30,
      threshold: 30,
      limits: {
        min: 0
      }
    },
    zoom: {
      enabled: true,
      drag: false,
      mode: "x",
      limits: {
        max: 2,
        min: 5
      }
    },
    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
            return 'Mois ' + tooltipItem[0].xLabel;
          }
        }
      }
  };

  public chartData: Array<{
    data: Array<number>;
    label: string;
  }>;

  public chartX: Array<number>;
  private transacConsoHelper: Array<any>;

  constructor(
    public simulatorService: SimulatorService,
    public utils: Utils
  ) {
    this.chartData = [];
    this.chartX = [];
  }

  ngOnInit() {
    if (this.simulatorService.currentSimulation === undefined) {
      this.utils.goToHome();
      return false;
    }

    this.processChart();
  }

  setMonthGrouping(value: number) {
    this.groupedByMonth = value;
    this.lineData = {
      datasets: [],
      labels: []
    };
    this.processChart();
  }

  processChart() {
    const results = this.simulatorService.getCurrentSimulationResults(),
      duration = this.simulatorService.getDuration(),
      self = this,
      monthlyVolume = {
        mono: this.simulatorService.currentSimulation.data.vol_mono,
        color: this.simulatorService.currentSimulation.data.vol_color,
      };

    // console.log("RESULTS", results, monthlyVolume);
    this.transacConsoHelper = [];

    const tmp = _.range(0, duration + 1);
    _.each(tmp, (number) => {
      if (number % this.groupedByMonth === 0) {
        this.lineData.labels.push(number);
      }
    });

    const backgroundColor = [
      '#EB7200',
      '#a5dd26',
      '#00c7cb',
      '#eba200'
    ];

    _.each(results, (result, index) => {
      let tmpChartData = {
        data: [],
        label: result.product.ref_sap + ' (' + result.type + ')',
        borderColor: backgroundColor[index],
        backgroundColor: backgroundColor[index],
        pointRadius: 3,
        lineTension: 0,
      },
      monthlyCost = 0,
      baseCost = 0;

      if (result.type === 'MPS') {
        _.each(result.rates, (rate) => {
          if (rate.type === 'PREVISIONAL') {
            monthlyCost += rate.rate;
          } else {
            baseCost += rate.rate;
          }
        });

        // MPS IS ABOUT month by month cost
        monthlyCost = monthlyCost / duration;

        for (let i = 0; i <= duration; i++) {
          if (i % this.groupedByMonth === 0) {
            tmpChartData.data.push(
              _.round(baseCost + (monthlyCost * i), 2)
            );
          }
        }
      } else if (result.type === 'TRANSAC') {
        let prevCosts = [];
        _.each(result.rates, (rate) => {
          if (rate.type === 'PREVISIONAL') {
            // Ink/Toner + Waste cost

            _.each(rate.details, (detail) => {
              this.transacConsoHelper.push({
                type: detail.type,
                availableVolume: detail.volume.starter,
                unitPrice: detail.unit,
                capacity: detail.capacity,
              });
            });

            prevCosts.push(rate);
          } else {
            // Warranty + Base cost
            baseCost += rate.rate;
          }
        });

        // console.log('Heyoyoyoyo', baseCost, prevCosts, this.transacConsoHelper);
        for (let i = 0; i <= duration; i++) {
          monthlyCost = 0;

          // Get through the helper and sum up consumable prices if needed
          _.each(this.transacConsoHelper, (help) => {
            switch (help.type) {
              case 'MONO':
              case 'BU':
              case 'DR':
              case 'WT':
                help.availableVolume -= monthlyVolume.mono + monthlyVolume.color;
                // console.log('MONO CASE', help);
                break;
              case 'COLOR':
                help.availableVolume -= monthlyVolume.color;
                // console.log('COLOR CASE', help);
                break;
            }

            while (help.availableVolume < 0) {
              monthlyCost += help.unitPrice;
              help.availableVolume += help.capacity;
            }
          });
          // console.log(this.transacConsoHelper);

          baseCost = _.round(baseCost + monthlyCost, 2);

          if (i % this.groupedByMonth === 0) {
            tmpChartData.data.push(
              baseCost
            );
          }
        }
      }

      self.lineData.datasets.push(tmpChartData);
    });
  }
}
