import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  headers = new HttpHeaders({
    'Authorization': environment.token,
    'Content-type': 'application/json',
  });
  options = ({headers: this.headers});

  public urls = environment.rest;
  public loading = false;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  loadIn() {
    this.loading = true;
  }
  loadOff() {
    this.loading = false;
  }

  getProducts() {
    this.loading = true;

    let time = navigator.onLine ? `?v=${(new Date()).getTime()}` : '';

    return this
      .http
      .get(`${this.urls.get_products}${time}`, this.options)
      .finally(() => (this.loading = false));
  }

  getProduct() {
    this.loading = true;
    return this
      .http
      .get(this.urls.get_products, this.options)
      .finally(() => (this.loading = false));
  }

  postSimulation(simulation: any, isRetry = false) {
    this.loading = !isRetry;
    let headers = _.clone(this.headers);
    headers = headers.append('X-Token', localStorage.getItem('currentToken'));

    return this
      .http
      .post(
        this.urls.post_simulation,
        _.merge(
          {isRetry, user_email: localStorage.getItem('currentUser')},
          simulation
        ),
        {headers}
      )
      .finally(() => (this.loading = false));
  }

  shareSimulationToCurrentUser(simulation: any, isRetry = false) {
    this.loading = !isRetry;
    let headers = _.clone(this.headers);
    headers = headers.append('X-Token', localStorage.getItem('currentToken'));

    return this.http.post(
      this.urls.share_simulation,
      {
        isRetry,
        user_email: localStorage.getItem('currentUser'),
        simulation_uuid: simulation.uuid,
        name: simulation.name,
        target_email: simulation.target_email + '@brother.fr'
      },
      {headers}
    ).finally(() => (this.loading = false));
  }

  async logout() {
    let headers = _.clone(this.headers);
    let token   = await localStorage.getItem('currentToken');

    this.http.post(
      this.urls.log_out, { token }, { headers }
    ).subscribe();
  }

  /**
   * Extract data
   * @param {[Response]} res
   */
  private extractData(res: Response) {
    let body = res;
    if (res) {
      if (res.status === 201) {
        return [{status: res.status, json: res}];
      } else if (res.status === 200) {
        return [{status: res.status, json: res}];
      }
    }
    return body || {};
  }

  /**
   * Show error
   * @param {[any]} error
   */
  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }

  /**
   * Show error
   * @param {[type]} url
   * @param {[any]} data
   */
  public postRequest(url, data: any = {}): Observable<any> {
    return this.http.post(url, data, this.options)
      .map(this.extractData)
      .catch(this.handleError);
  }
}
