import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { User } from './user.class';
import { SimulatorService } from '../../services/simulator.service';

import { ToastrService } from 'ngx-toastr';


@Injectable()
export class Utils {

  constructor(
    private Router: Router,
    private User: User,
    private Location: Location,
    private toastr: ToastrService
  ) {}

  //-- GO TO

  goToLogin() {
    this.Router.navigateByUrl('/auth');
  }

  goToHome() {
    this.Router.navigateByUrl('/home');
  }

  goToResultats() {
    this.Router.navigateByUrl('/resultats');
  }

  goToOptions() {
    this.Router.navigateByUrl('/ajouter-options');
  }

  goToFunctions() {
    this.Router.navigateByUrl('/ajouter-fonctions');
  }

  goToResultatsGraphique() {
    this.Router.navigateByUrl('/graphiques');
  }

  goToFinalView() {
    this.Router.navigateByUrl('/choix-final');
  }

  goToPwdSuccess() {
    this.Router.navigateByUrl('/pwd-success');
  }

  goToRegisterSuccess() {
    this.Router.navigateByUrl('/register-success');
  }


  /**
  * Return error message
  */
  handleError(error = null) {
    if (!error) error = 'Les informations saisies sont invalides. Veuillez réessayer.';
    this.toastr.error(error);
  }

  redirectLogoutUser() {
    this.User.redirectLogoutUser();
  }

  goBack() {
    var component = document.referrer.split("/")[3];
    if( document.URL.split("/")[3] == "login" ){
      if(component == undefined  ){
        this.Router.navigateByUrl('/home');
      } else {
        this.Location.back();
      }
    } else {
      this.Location.back();
    }
  }
}
