import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goback',
  templateUrl: './goback.component.html',
})
export class GobackComponent implements OnInit {

  //-- LIFECYCLE

  constructor() {
  }

  ngOnInit() {
  }

}
