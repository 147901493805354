import {Component, Inject, OnInit} from '@angular/core';
import 'rxjs/add/observable/interval';
import { ApiService } from './services/api.service';
import {Products} from './shared/class/products.class';
import { ConnectionService } from 'ng-connection-service';
import { ReplayerService } from './services/replayer.service';
import { User } from './shared/class/user.class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(
    public apiService: ApiService,
    public user: User,
    private products: Products,
    private connectionService: ConnectionService,
    private replayer: ReplayerService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        replayer.initReplay();
      }
    });
  }

  ngOnInit() {}
}
