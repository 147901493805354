import {
  Injectable
} from '@angular/core';

import {
  v4 as uuid
} from 'uuid';

import {
  DateTime
} from 'luxon';

import {
  ApiService
} from './api.service';

import {
  CalculatorService
} from './calculator.service';

import * as _ from 'lodash';
import { Products } from '../shared/class/products.class';

enum SimulationStatus {
  INITIATED,
  CALCULATED,
  SELECTED,
  COMPLETED,
  SHARED
}

enum Connectivities {
  WIFI = 'Wifi',
  NETWORK = 'Réseau',
  USB = 'USB',
  WIFI_NETWORK = 'Wifi/Réseau',
}

interface Simulation {
  status: SimulationStatus;
  data: SimulationApi;
}

interface SimulationApi {
  name?: string; // ONLY FOR SHARING SIMULATION
  target_email?: string; // ONLY FOR SHARING SIMULATION
  uuid: string;
  type: string;
  duration: number;
  quantity: number;
  vol_mono: number;
  vol_color: number;
  created_at: string;
  selected_model_ref_sap?: string; // When user select a ref in the result page
  selected_model_is_transac?: boolean; // When user select a ref in the result page
  model_ref_sap?: string; // When user select a ref in the form
  mono_color?: 'MONO'|'COLOR';
  format?: 'A4'|'A3';
  products?: Array<any>;
  techno?: 'Laser'|'InkJet';
  need?: 'Printer'|'3 en 1'|'4 en 1';
  connectivity?: Connectivities;
  ppm?: {
    mono: number;
    color: number;
  };
  sizing?: {
    width: number;
    height: number;
    depth: number;
  };
}

interface SimulationProductRates {
  type: string;
  rate: number;
  details?: any;
}

interface SimulationProduct {
  ref_sap: string;
  costs: Array < SimulationProductRates > ;
}

@Injectable({
  providedIn: 'root'
})

export class SimulatorService {
  public currentSimulation: Simulation;

  constructor(
    private apiService: ApiService,
    private calculatorService: CalculatorService,
    private products: Products
  ) {}

  initEmptySimulation(
    type: string,
    duration: number,
    quantity: number,
    vol_mono: number,
    vol_color: number
  ) {
    this.currentSimulation = {
      status: SimulationStatus.INITIATED,
      data: {
        uuid: uuid(),
        type,
        duration,
        quantity,
        vol_mono,
        vol_color,
        created_at: DateTime.local().toISO(),
      }
    };

    return this
      .apiService
      .postSimulation(
        this.currentSimulation.data,
        false
      );
  }

  initCalculatedSimulation(
    methode_vente,
    selectedRefSap: string,
    techno,
    color,
    need,
    connectivity,
    format: 'A4'|'A3',
    volMono: number,
    volColor: number,
    ppmMono: number,
    ppmColor: number,
    width: number,
    height: number,
    depth: number
  ) {
    this.currentSimulation.data.model_ref_sap = selectedRefSap;
    this.currentSimulation.data.type = methode_vente;
    this.currentSimulation.data.format = format;
    this.currentSimulation.data.techno = techno;
    this.currentSimulation.data.need = need;
    this.currentSimulation.data.connectivity = connectivity;

    this.currentSimulation.data.ppm = {
      mono: ppmMono,
      color: ppmColor
    };

    this.currentSimulation.data.sizing = {
      width,
      depth,
      height,
    };

    if (color !== undefined) {
      this.currentSimulation.data.mono_color = color;
    } else if (volColor > 0) {
      this.currentSimulation.data.mono_color = 'COLOR';
    }

    const rates = this
      .calculatorService
      .calculateTCO(
        this.currentSimulation.data
      );

    this.currentSimulation.data.products = rates;
    this.currentSimulation.status = SimulationStatus.CALCULATED;

    return this
      .apiService
      .postSimulation(
        this.currentSimulation.data,
        false
      );
  }

  initSelectedRefSimulation(ref_sap: string, is_transac: boolean) {
    this.currentSimulation.status = SimulationStatus.SELECTED;
    this.currentSimulation.data.selected_model_ref_sap = ref_sap;
    this.currentSimulation.data.selected_model_is_transac = is_transac;

    return this
      .apiService
      .postSimulation(
        this.currentSimulation.data,
        false
      );
  }

  cleanPluginsCosts(type) {
    const selectedProduct = _.find(
        this.currentSimulation.data.products,
        ['product', this.currentSimulation.data.selected_model_ref_sap]
    );

    if (type === 'OPTIONS') {
      _.remove(selectedProduct.costs, ['type', 'OPTIONS']);
    }
    if (type === 'FUNCTIONS') {
      _.remove(selectedProduct.costs, ['type', 'FUNCTIONS']);
    }
  }

  addPluginsCosts(pluginsCosts: Array<any>) {
    this.currentSimulation.status = SimulationStatus.COMPLETED;
    let selectedProduct;
    const self = this;

    _.each(this.currentSimulation.data.products, function(p) {
      if (p.product === self.currentSimulation.data.selected_model_ref_sap) {
          if (
              (self.currentSimulation.data.selected_model_is_transac && p.type === 'TRANSAC')
              || (!self.currentSimulation.data.selected_model_is_transac && p.type === 'MPS')
          ) {
              selectedProduct = p;
          }
      }
    });

    // Check if ref sap exist ? yes > update rate : no > push cost
    _.each(pluginsCosts, (cost) => {
      selectedProduct.costs.push(cost);
    });
  }

  getCurrentSimulationResults() {
    if (this.currentSimulation.data.products.length === 0) {
      return [];
    }

    let products = [];
    _.each(this.currentSimulation.data.products, (productSimulation) => (
        products.push({
          product: this.products.getProductByRef(productSimulation.product),
          rates: productSimulation.costs,
          type: productSimulation.type,
        })
      )
    );

    return products;
  }

  getDuration() {
    return this.currentSimulation.data.duration;
  }

  getFunctionsForSelectedRef() {
    return this.products.getFunctionsByProductRef(
      this.currentSimulation.data.selected_model_ref_sap
    );
  }

  getOptionsForSelectedRef() {
    return this.products.getOptionsByProductRef(
      this.currentSimulation.data.selected_model_ref_sap
    );
  }

  getSelectedProduct() {
    return this.products.getProductByRef(
      this.currentSimulation.data.selected_model_ref_sap
    );
  }

  getSelectedProductCost() {
    // console.log(
    //     'getSelectedProductCost',
    //     this.currentSimulation.data.products,
    //     this.currentSimulation.data
    // );

    let costs = [];
    const self = this;

    _.each(this.currentSimulation.data.products, function(p) {
      if (p.product === self.currentSimulation.data.selected_model_ref_sap) {
        if (
          (self.currentSimulation.data.selected_model_is_transac && p.type === 'TRANSAC')
          || (!self.currentSimulation.data.selected_model_is_transac && p.type === 'MPS')
        ) {
          costs = p.costs;
        }
      }
    });

    return costs;
  }

  getSelectedProductType() {
    return this.currentSimulation.data.selected_model_is_transac ? 'TRANSAC' : 'MPS';
  }

  hasResults() {
    return (this.currentSimulation.data.products !== undefined && this.currentSimulation.data.products.length > 0);
  }

  resetSimulation() {
    this.currentSimulation = undefined;
  }
}
