import {Component, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import { SimulatorService } from '../../services/simulator.service';
import { Utils } from '../../shared/class/utils.class';

@Component({
  selector: 'app-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.scss']
})
export class ResultsPageComponent implements OnInit {
  public slideConfig = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'dots': true,
    'infinite': false,
    'adaptiveHeight': false,
    'lazyLoad': 'progressive',
    'cssEase': 'ease',
    'easing': 'swing',
    'arrows': true,
    'mobileFirst': true,
    'onInit': function () {
      $(window).resize();
    },
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 2,
          'slidesToScroll': 2,
          'arrows': true,
          'adaptiveHeight': true,
        }
      }
    ]
  };

  public results: Array<any>;
  public simulation: any;

  constructor(
    private simulatorService: SimulatorService,
    private Utils: Utils
  ) {
    if (this.simulatorService.currentSimulation === undefined) {
      this.Utils.goToHome();
    }
  }

  ngOnInit() {
    this.results = this.simulatorService.getCurrentSimulationResults();
    this.simulation = this.simulatorService.currentSimulation;

    $(document).ready(function () {
      if (screen.width < 460) {
        // console.log('Less than 460');
      } else {
        // console.log('More than 960');
      }
      $('.btn').on('shown.bs.tab', function (e) {
        $('.carousel').resize();
      });
    });
  }

  slickInit(e) {
    //console.log('slick initialized');
  }

  afterChange(e) {
    //console.log('afterChange');
  }

  beforeChange(e) {
    //console.log('beforeChange');
  }
}
