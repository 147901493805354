import {Component, OnInit, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {User} from '../shared/class/user.class';
import {Utils} from '../shared/class/utils.class';

import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Products } from '../shared/class/products.class';
import { SimulatorService } from '../services/simulator.service';

import * as _ from 'lodash';
import { CalculatorService } from '../services/calculator.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  scrolled = false;

  methodeVenteRadio: number;
  durations: Array<any>;
  connectivities: Array<any>;
  needs: Array<any>;
  technologies: Array<any>;
  quantities: Array<number>;
  references: Array<any>;
  color: string;

  selectedReference: any;
  selectedRefSap: string;
  selectedNeed: any;
  selectedTechnology: any;
  selectedConnectivity: any;
  selectedDuration: number;
  selectedQuantity: number;
  openIds = [];

  monoVolume: number;
  colorVolume: number;

  isValidated: boolean;
  isFormatA3: boolean;
  console = console;

  groupOptions: any;
  contentDimension: any;
  contraintesDimensionsInput: any;
  validerBtnClass: boolean = true;
  htmlStr: string = '<div ngModel class="form-group">\n' +
    '<div class="form-select-wrapper">\n' +
    '<select class="form-control">\n' +
    '<option value="" disabled>--</option>\n' +
    '<option value=""></option>\n' +
    '<option value=""></option>\n' +
    '</select>\n' +
    '</div>\n' +
    '</div>';

  validLargeur: boolean = true;

  // Advanced fields [Size / PPM]
  sizeWidth: number;
  sizeDepth: number;
  sizeHeight: number;
  ppmMono: number;
  ppmColor: number;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    public utils: Utils,
    private user: User,
    private products: Products,
    private simulatorService: SimulatorService,
    private calculatorService: CalculatorService,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {
    if (!this.user.isLogin()) {
      this.utils.goToLogin();
    }
  }

  ngOnInit() {
    if (!this.user.isLogin()) {
      this.utils.goToLogin();
    }

    let presetData = {
      type: 'MPS',
      model_ref_sap: null,
      techno: null,
      mono_color: 'NONE',
      need: null,
      connectivity: null,
      format: 'A4',
      duration: 36,
      quantity: 1,
      vol_mono: 1,
      vol_color: 0,
      ppm: {
          mono: 0,
          color: 0,
      },
      sizing: {
          width: 0,
          height: 0,
          depth: 0
      }
    };

    this
      .apiService
      .getProducts()
      .subscribe(
        productCollection => {
          this.products.parseProducts(productCollection);
          this.initReferences(this.methodeVenteRadio);
          // console.log(
          //   'selectedRef',
          //   presetData.model_ref_sap, this.selectedReference,
          //   this.products.getProductByRef(presetData.model_ref_sap)
          // );
          if (presetData.model_ref_sap !== null && presetData.model_ref_sap !== undefined) {
            // console.log('Preset Has Model Ref', presetData.model_ref_sap);
            this.selectedRefSap = presetData.model_ref_sap;
            this.selectedReference = this.products.getProductByRef(presetData.model_ref_sap);
            // console.log('Selected Ref;', this.selectedReference);
            this.fillFormByProduct(
              this.selectedReference
            );
          } else {
            this.selectedRefSap = null;
            this.selectedReference = null;
          }
        }
      );

      this.document.body.classList.remove('hide_nav');

      console.log('PresetData', presetData);
      console.log('Current Simulation', this.simulatorService.currentSimulation);

      if (this.simulatorService.currentSimulation !== undefined && this.simulatorService.currentSimulation.data !== undefined) {
          const simData = this.simulatorService.currentSimulation.data;
          // console.log('SimData', simData);
          presetData = _.merge(presetData, simData);
          this.isValidated = true;
          this.validerBtnClass = false;
      }
      // console.log('PresetData After', presetData);

      if (presetData.type === 'MPS') {
          this.methodeVenteRadio = 0;
      } else {
          this.methodeVenteRadio = 1;
      }

      this.color = presetData.mono_color;
      this.isFormatA3 = presetData.format === 'A3';

      this.durations = [
          {value: 36, name: '36 mois', disabled: false},
          {value: 48, name: '48 mois', disabled: false},
          {value: 60, name: '60 mois', disabled: false},
      ];
      this.selectedDuration = presetData.duration;

      this.technologies = [
          {value: 'Laser', name: 'Laser', disabled: false},
          {value: 'InkJet', name: 'InkJet', disabled: false},
      ];
      this.selectedTechnology = presetData.techno;

      this.needs = [
          {value: 'Printer', name: 'Printer', disabled: false},
          {value: '3 en 1', name: '3 en 1', disabled: false},
          {value: '4 en 1', name: '4 en 1', disabled: false},
      ];
      this.selectedNeed = presetData.need;

      this.connectivities = [
          {value: 'Wifi', name: 'Wifi', disabled: false},
          {value: 'Réseau', name: 'Réseau', disabled: false},
          {value: 'USB', name: 'USB', disabled: true},
          {value: 'Wifi/Réseau', name: 'Wifi/Réseau', disabled: false},
      ];
      this.selectedConnectivity = presetData.connectivity;

      this.monoVolume = presetData.vol_mono;
      this.colorVolume = presetData.vol_color;

      this.quantities = _.range(1, 21);
      this.selectedQuantity = presetData.quantity;

      this.ppmMono = presetData.ppm.mono;
      this.ppmColor = presetData.ppm.color;
      this.sizeWidth = presetData.sizing.width;
      this.sizeDepth = presetData.sizing.depth;
      this.sizeHeight = presetData.sizing.height;
      if (this.sizeWidth > 0 || this.sizeDepth > 0 || this.sizeHeight > 0) {
          this.openIds.push('container_dimensions');
      }
      if (this.ppmMono > 0 || this.ppmColor > 0) {
          this.openIds.push('vitesse_impression');
      }

      // console.log('Pistache REF', this.references, this.selectedReference);
      // console.log('Simulation: ', this.simulatorService.currentSimulation, presetData);
  }

  ngAfterViewChecked() {
    // console.log('ngAfterViewChecked', this.isValidated, this.validerBtnClass);
    if (this.isValidated && !this.validerBtnClass && !this.scrolled) {
      // console.log('in', document.getElementById('noir_mensuel').getBoundingClientRect().top);
      try {
        window.scrollTo({
          left: 0,
          top: document.getElementById('noir_mensuel').getBoundingClientRect().top
        });
        setTimeout(() => { // this will make the execution after the above boolean has changed
          document.getElementById('noir_mensuel').focus();
        }, 0);

        this.scrolled = true;
      } catch (err) {}
    }
  }

  submitModeleSearch() {
    if (this.isValidated) {
      this.selectedReference = null;
      this.onReferencePick(null);
    }

    this.isValidated = true;
    this.validerBtnClass = false;
  }

  onSubmit(f: NgForm) {
    if (f.form.controls.noir_mensuel !== undefined && f.form.controls.couleur_mensuel !== undefined) {
      // console.log('Errors ONSUBMIT', this.colorVolume, this.monoVolume);
      if (this.colorVolume <= 0 && this.monoVolume <= 0) {
        f.form.controls.noir_mensuel.setErrors({incorrect: true});
        f.form.controls.couleur_mensuel.setErrors({incorrect: true});
      } else {
        f.form.controls.noir_mensuel.setErrors(null);
        f.form.controls.couleur_mensuel.setErrors(null);
      }
    }

    if (this.isFormValid(f)) {
      let methodeVente = 'MPS';

      if (f.value.methode_vente === 1) {
        methodeVente = 'TRANSAC';
      }

      const qty = Number.parseInt(f.value.nombre_machine_selection, 10),
        volMono = Number.parseInt(f.value.noir_mensuel, 10),
        volColor = Number.parseInt(f.value.couleur_mensuel, 10),
        duration = Number.parseInt(f.value.duree_utilisation_machine_selection, 10),
        color = this.color === 'NONE' ? null : this.color;


      this
        .simulatorService
        .initEmptySimulation(
          methodeVente,
          _.isNaN(duration) ? 0 : duration,
          _.isNaN(qty) ? 0 : qty,
          _.isNaN(volMono) ? 0 : volMono,
          _.isNaN(volColor) ? 0 : volColor
        );

      if (this.selectedTechnology === 'null') {
        this.selectedTechnology = null;
      }
      if (this.selectedReference === 'null') {
        this.selectedReference = null;
      }
      if (this.selectedConnectivity === 'null') {
        this.selectedConnectivity = null;
      }
      if (this.selectedNeed === 'null') {
        this.selectedNeed = null;
      }

      this
        .simulatorService
        .initCalculatedSimulation(
          methodeVente,
          this.selectedReference !== null && this.selectedReference !== undefined ? this.selectedReference.ref_sap : undefined,
          this.selectedTechnology,
          color,
          this.selectedNeed,
          this.selectedConnectivity,
          this.isFormatA3 === true ? 'A3' : 'A4',
          this.monoVolume,
          this.colorVolume,
          this.ppmMono,
          this.ppmColor,
          this.sizeWidth,
          this.sizeHeight,
          this.sizeDepth
        )
        .subscribe(
          () => {
            if (this.simulatorService.hasResults()) {
              this.utils.goToResultats();
            } else {
              this.toastr.warning('Vérifiez vos critères où contacter notre équipe commerciale', 'Aucun résultat,');
            }
          },
          () => {
            if (this.simulatorService.hasResults()) {
            this.utils.goToResultats();
          } else {
            this.toastr.warning('Vérifiez vos critères où contacter notre équipe commerciale.', 'Aucun résultat,');
          }
        });
    } else {
      this.toastr.error('Le formulaire contient des erreurs et/où des incompatibilités.');
    }
  }

  onSimulationTypePick(type: number) {
    this.initReferences(type);
  }

  onReferencePick(refSap: string|null) {
    // console.log('OnReferencePick : ', refSap, this.selectedReference);
    // // let selecteProduct = undefined;
    // if (refSap !== null) {
    //   this.selectedReference = this.products.getProductByRef(refSap);
    // }

    this.fillFormByProduct(this.products.getProductByRef(refSap));
  }

  fillFormByProduct(product: any) {
    this.selectedReference = product;
    if (_.has(product, 'techno')) {
      this.selectedTechnology = _.find(this.technologies, function(tech) {
        return tech.value === product.techno;
      }).value;
    } else {
      this.selectedTechnology = null;
    }
    // console.log(this.selectedTechnology);
    // console.log(
    //     'besoin',
    //     _.has(product, 'besoin'),
    //     this.needs,
    //     this.selectedNeed
    // );
    if (_.has(product, 'besoin')) {
      this.selectedNeed = _.find(this.needs, function(need) {
        return need.value === product.besoin;
      }).value;
    } else {
      this.selectedNeed = null;
    }
    // console.log(
    //     'connectivites',
    //     _.has(product, 'connectivites'),
    //     product,
    //     this.connectivities,
    //     this.selectedConnectivity
    // );
    if (_.has(product, 'connectivites') && product.connectivites !== '') {
      this.selectedConnectivity = _.find(this.connectivities, function(conn) {
        return conn.value === product.connectivites;
      }).value;
    } else {
      this.selectedConnectivity = null;
    }

    if (_.has(product, 'format')) {
      if (product.format === 'A3') {
        this.isFormatA3 = true;
      } else {
        this.isFormatA3 = false;
      }
    } else {
      this.isFormatA3 = false;
    }

    if (_.has(product, 'sizing')) {
      this.sizeWidth = product.sizing.width;
      this.sizeHeight = product.sizing.height;
      this.sizeDepth = product.sizing.depth;
      this.openIds.push('container_dimensions');
    } else {
      this.openIds = _.remove(this.openIds, 'container_dimensions');
      this.sizeWidth = 0;
      this.sizeHeight = 0;
      this.sizeDepth = 0;
    }

    if (_.has(product, 'ppm')) {
      this.ppmColor = product.ppm.color;
      this.ppmMono = product.ppm.mono;
      this.openIds.push('vitesse_impression');
    } else {
      this.openIds = _.remove(this.openIds, 'vitesse_impression');
      this.ppmColor = 0;
      this.ppmMono = 0;
    }
    // console.log(this.openIds);

    // console.log(
    //     'mono_color',
    //     _.has(product, 'mono_color'),
    //     product.mono_color,
    //     this.color
    // );
    if (_.has(product, 'mono_color')) {
      switch (product.mono_color) {
        case 'COLOR':
          this.color = 'COLOR';
          break;
        case 'MONO':
          this.color = 'MONO';
          break;
        default:
          this.color = 'NONE';
          break;
      }
    } else {
      this.color = 'NONE';
    }
  }

  private initReferences(type: number) {
    let products = [];
    if (type === 0) {
      products = this.products.mps;
    } else {
      products = this.products.transac;
    }

    // console.log('initReferences', this.selectedReference);
    if (this.selectedReference !== null) {
      const newProduct = _.find(products, this.selectedReference);

      // console.log('Init ref', newProduct);

      if (newProduct === null || newProduct === undefined) {
        // Si le produit selectionner n'est pas dans la nouvelle liste des produits
        // On reset completement le formulaire pour pas avoir des data qui partent dans tout les sens
        this.selectedRefSap = null;
        this.selectedReference = null;
        this.onReferencePick(null);
      } else {
        this.selectedRefSap = _.get(newProduct, 'ref_sap');
        this.selectedReference = newProduct;
      }
    }

    this.references = products;
    // console.log('initReferences', this.references);
  }

  public isFormValid(f: NgForm) {
    // f.form.setErrors({incorrect: true});
    if (!f.submitted && f.form.pristine) {
      return false;
    } else if (f.submitted && f.form.pristine === false) {
      const noir = f.form.controls.noir_mensuel,
        color = f.form.controls.couleur_mensuel;

      if (noir.value <= 0 && color.value <= 0) {
        f.form.controls.noir_mensuel.setErrors({incorrect: true});
        f.form.controls.couleur_mensuel.setErrors({incorrect: true});
      } else if (noir.value + color.value > 8000) {
        f.form.controls.noir_mensuel.setErrors({incorrect: true});
        f.form.controls.couleur_mensuel.setErrors({incorrect: true});
      } else {
        f.form.controls.noir_mensuel.setErrors(null);
        f.form.controls.couleur_mensuel.setErrors(null);
      }
    }
    // console.log('isFormValid', f.form.controls);

    return f.form.valid;
  }

  public filterFormChoices(f: NgForm) {
    if (f.form.controls.technologie_selection !== undefined && f.form.controls.format_a3 !== undefined) {
      // Laser into A3 format incompatibility
      if (f.form.controls.technologie_selection.value === 'Laser') {
        // Deal with form issue
        f.form.controls.format_a3.setValue(false);
        f.form.controls.format_a3.disable();
      } else {
        f.form.controls.format_a3.enable();
      }
    }

    if (
      this.methodeVenteRadio === 0
      || (
        f.form.controls.technologie_selection !== undefined
        && f.form.controls.technologie_selection.value === 'Laser'
      )
    ) {
      _.find(this.connectivities, ['value', 'USB']).disabled = true;
      if (this.selectedConnectivity === 'USB') {
        this.selectedConnectivity = undefined;
      }
    } else {
      _.find(this.connectivities, ['value', 'USB']).disabled = false;
    }
  }

  public updateColorNeed() {
    switch (this.color) {
      case 'MONO':
        if (this.selectedTechnology === 'InkJet') {
          this.toastr.error(
              'Aucun modèle Laser n\'est disponible avec un besoin d\'impression en Noir'
          );
        }

        this.colorVolume = 0;
        this.selectedTechnology = 'Laser';
        break;
    }
  }
}
