import { Component, OnInit, Input } from '@angular/core';
import { NgbAccordion , NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { SimulatorService } from '../../../services/simulator.service';
import { Utils } from '../../../shared/class/utils.class';

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.scss']
})
export class SinglePageComponent implements OnInit {

  @Input() index: number;
  @Input() id: number;

  @Input() result: any;
  @Input() simulation: any;

  public simulationRate: number;
  public currentSimulation: any;

  closeResult: string;
  constructor(
    private modalService: NgbModal,
    private simulatorService: SimulatorService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.currentSimulation = this.simulatorService.currentSimulation;
    if (this.currentSimulation === undefined) {
      this.utils.goToHome();
    }

    this.simulationRate = 0;
    _.each(this.result.rates, (rateObject) => (
      this.simulationRate += rateObject.rate
    ));

    this.simulationRate = _.round(this.simulationRate, 2);

    // console.log('Result', this.result);
  }

  onReferencePick(ref_sap: string, is_transac: boolean) {
    this
    .simulatorService
    .initSelectedRefSimulation(ref_sap, is_transac)
    .subscribe(() => (this.utils.goToOptions()), () => (this.utils.goToOptions()));
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  toggle(acc: NgbAccordion) {
    if (acc.activeIds.length) {
      acc.activeIds = [];
    } else {
      acc.activeIds = [0, 1, 2].map(i => `ngb-panel-${i}`);
    }
  }

  // HandleRedirect (ROUTER)
  // Recup le router et on redirige en fonction de si le produit a des options et functions (xor)
}
