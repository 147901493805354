import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {SimulatorService} from '../../../services/simulator.service';
import {CalculatorService} from '../../../services/calculator.service';
import {ApiService} from '../../../services/api.service';

import {Utils} from '../../../shared/class/utils.class';
import {FormBuilder, FormGroup, FormArray, NgForm} from '@angular/forms';
import * as _ from 'lodash';

@Component({
    selector: 'app-add-options',
    templateUrl: './add-functions.component.html',
    styleUrls: ['./add-functions.component.scss']
})
export class AddFunctionsComponent implements OnInit {
    noFunctions = true;

    public availableFunctions: Array<any>;
    public selectedFunctions = [];

    public myForm: FormGroup;
    public currentProduct: any;

    public functions: {
        bcu: any,
        sp: any,
        cui: any,
        bp: any,
        os: any,
        rh: any,
        rp: any,
        tcp: any,
    };

    console = console;

    constructor(
        private _fb: FormBuilder,
        private apiService: ApiService,
        private simulatorService: SimulatorService,
        private calculatorService: CalculatorService,
        private utils: Utils
    ) {
    }

    ngOnInit() {
        // console.log(this.simulatorService.currentSimulation, this.noFunctions, this.noOptions);
        if (this.simulatorService.currentSimulation === undefined) {
            this.utils.goToHome();
            return false;
        }

        this.currentProduct = this.simulatorService.getSelectedProduct();
        this.selectedFunctions = [];
        this.functions = this.simulatorService.getFunctionsForSelectedRef();

        _.each(this.functions, func => {
            if (func !== false) {
                this.noFunctions = false;
            }
        });

        if (this.noFunctions) {
            this.utils.goToFinalView();
            return false;
        }

        this.availableFunctions = [
            this.initFunctions(),
        ];
    }

    /**
     * FUNCTIONS+ OF PRINTER.
     * Each function can only be picked one time.
     * Function+ Can be found in Product Utils under the options collection.
     * It had the same data format as Options.
     */
    initFunctions() {
        const tmp = [];

        _.each(this.functions, (func) => {
            if (_.has(func, 'ref_sap')) {
                tmp.push(func);
            }
        });

        return tmp;
    }

    save(form: NgForm) {
        // console.log('Form SAVE');
        // console.log(this.selectedFunctions);
        this.simulatorService.cleanPluginsCosts('FUNCTIONS');

        // console.log(this.selectedFunctions.length);
        if (this.selectedFunctions.length > 0) {
            const functionCosts = this.calculatorService.getFunctionsCosts(
                this.selectedFunctions
            );

            // console.log('functionCosts');
            // console.log(functionCosts);
            this.simulatorService.addPluginsCosts(functionCosts);

            this
                .apiService
                .postSimulation(
                    this.simulatorService.currentSimulation.data,
                    false
                )
                .subscribe(
                    () => (this.utils.goToFinalView()),
                    () => (this.utils.goToFinalView())
                );
        } else {
            this.utils.goToFinalView();
        }
    }

    // MANAGE SELECT/UNSELECT FUNCTIONS
    selectFunc = (ref) => {
        // console.log('this.selectedFunctions (SELECT)');
        // console.log(this.selectedFunctions);
        this.selectedFunctions.push(
            _.filter(this.functions, {'ref_sap': ref})[0].ref_sap
        );
        // console.log(this.selectedFunctions);
    }

    unselectFunc = (ref) => {
        // console.log('this.selectedFunctions (UNSELECT)');
        // console.log(this.selectedFunctions);
        this.selectedFunctions = _.remove(
            this.selectedFunctions,
            ref
        );
        // console.log(this.selectedFunctions);
    }
}
