import { Component, OnInit } from '@angular/core';
import { Utils } from '../shared/class/utils.class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {
  constructor(
    public utils: Utils,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() { }
}
