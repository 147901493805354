import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { Variables } from '../shared/config/variables';
import { Utils } from '../shared/class/utils.class';
import { User } from '../shared/class/user.class';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-passwordlost',
  templateUrl: './passwordlost.component.html',
  styleUrls: ['./passwordlost.component.scss']
})
export class PasswordlostComponent implements OnInit {

  private errorChampsEmpty = Variables.errorChampsEmpty;

  email: string = '';
  validEmail: boolean = true;
  success = false;

  constructor(@Inject(DOCUMENT)
  private document: Document,
    private router: Router,
    public utils: Utils,
    private user: User,
    private api: ApiService,
  ) {
    if (this.user.isLogin()) {
      this.utils.goToHome();
    }
  }

  ngOnInit() {
  }

  submitPwd() {
    if (this.email.match(/^.+@.+\..+$/)) {
      this.validEmail = true;
      this.api.loadIn();
      this.api
        .postRequest(
          this.api.urls.forgot_password,
          {
            email: this.email
          }
        )
        .subscribe(
          res => {
            this.success = true;
            this.api.loadOff();
          },
          err => {
            this.success = false;
            this.validEmail = false;
            this.api.loadOff();
          }
        );
    } else {
      this.validEmail = false;
    }

    return false;
  }
}
