import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {ApiService} from '../services/api.service';
import {Location} from '@angular/common';
import {Utils} from '../shared/class/utils.class';
import {Variables} from '../shared/config/variables';
import {User} from '../shared/class/user.class';

import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  providers: [ApiService, User, Utils]
})
export class AuthComponent implements OnInit {
  // -- PROPERTIES
  email: string = '';
  password: string = '';
  validPassword: boolean = true;
  validEmail: boolean = true;

  // -- LIFECYCLE
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private apiService: ApiService,
    private Location: Location,
    public Utils: Utils,
    private User: User,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.document.body.classList.add('hide_nav');

    if (this.User.isLogin()) {
      this.Utils.goToHome();
    }
  }

  onSubmit(f: NgForm) {
  // console.log(f);
    if (this.email !== '' && this.email.match(/.+@.+\..+/) && this.password !== '') {
      const data = {
        login: this.email,
        password: this.password
      };

      this.apiService
        .postRequest(this.apiService.urls.log_in, data)
        .subscribe(
          res => {
            this.User.setUser(res.login, res.token);
            //alert('Connexion réussie ! Redirection en cours');
            this.Utils.goToHome();
          },
          error => {
            this.validPassword = true;
            this.validEmail = true;
            this.toastr.error('Merci de vérifier la validité de vos identifiants.');
          }
        );
    } else {
      this.validPassword = false;
      this.validEmail = false;
      this.toastr.error('Merci de vérifier l\'existence et la validité de vos identifiants.');
    }

    return false;
  }

  /**
   * [selectedItem delete error]
   */
  selectedItem(value) {
    switch (value) {
      case 'password':
        this.validPassword = true;
        break;
      case 'email':
        this.validEmail = true;
        break;
      default:
        break;
    }

  }
}
