import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CgvComponent } from '../../../cms/cgv/cgv.component';

@Component({
  selector: 'app-footer-cgv',
  templateUrl: './footer-cgv.component.html',
  styleUrls: ['./footer-cgv.component.scss']
})
export class FooterCgvComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() { }

  open() {
    const modalRef = this.modalService.open(CgvComponent, {size: 'lg'});
    modalRef.componentInstance.name = 'CGV';
  }
}
