import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnInit {

  @Input() product: any;
  @Input() type: any;
  @Input() rates: any;
  @Input() simulation: any;

  public totalRate = 0;

  constructor() { }

  ngOnInit() {
    _.each(this.rates, (rate) => (
      this.totalRate += rate.rate
    ));

    this.totalRate = _.round(this.totalRate, 2);
  }
}
